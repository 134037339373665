import dayjs from "dayjs";

require("dayjs/locale/el");
dayjs.locale("el");

const ALFRESCO_URL = process.env.REACT_APP_ALFRESCO_URL;

export const transformToSmallNewsDate = (date) => {
  let dateObj = dayjs(date);
  let day = dateObj.format("DD");
  let month = dateObj.format("MMM");

  return { day, month };
};

export const getDateText = (date) => {
  if (!date) return "";
  let dateObj = dayjs(date);
  return `${dateObj.format("DD")} ${dateObj.format("MMMM")} ${dateObj.format(
    "YYYY"
  )}`;
};

export const transformImageUrl = (url) => {
  return url
    ? url.startsWith("http")
      ? url.replace("http://alfresco:8080", "http://172.16.3.8:8080")
      : (ALFRESCO_URL + url).replace(
          "http://alfresco:8080",
          "http://172.16.3.8:8080"
        )
    : "";
};
