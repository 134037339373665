import { useEffect, useState } from "react";

import { fetchNews } from "../../../Services/DataService";

import BlogBanner from "../../../Assets/images/blog-banner.jpg";

import Header from "../../../Components/Header/Header";
import NewsSection from "../../../Components/News/NewsSection";
import Footer from "../../../Components/Footer/Footer";
import "../news.css";

const NewsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      let news = await fetchNews(page, 6);
      setNews(news.items ?? []);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const moreClicked = () => {
    const fetchNextPage = async () => {
      let nextPageNews = await fetchNews(page + 1, 6);
      setNews([...news, ...(nextPageNews.items ?? [])]);
      setPage(page + 1);
    };

    fetchNextPage();
  };

  return (
    <>
      <Header />
      <main role="main">
        <div className="blog-banner">
          <img src={BlogBanner} alt="" />
        </div>
        <div className="blog-main">
          <div className="container">
            <div className="blog-top">
              <div className="row">
                <div className="col6">
                  <div className="blog-left">
                    <h2>Νέα – Ανακοινώσεις</h2>
                    <p>
                      H ενημέρωση γίνεται κοινότητα και η κοινότητα δίνει
                      σημασία σε κάθε λεπτομέρεια.
                    </p>
                  </div>
                </div>
                <div className="col6">
                  <div className="blog-right">
                    <h4>Εδώ μπορείτε να βρείτε</h4>
                    <p>
                      τα πιο πρόσφατα γεγονότα και ανακοινώσεις που αφορούν τη
                      ζωή στον τόπο μας. Από τις τοπικές εκδηλώσεις και τις
                      πολιτιστικές εκδηλώσεις μέχρι τα σημαντικά αποφάσεις του
                      δημοτικού συμβουλίου, εδώ θα βρείτε τα πάντα.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <NewsSection
              newsClass="news-inner blog-news"
              news={news}
              isLoading={isLoading}
            />
            <div style={{ width: "100%", textAlign: "center" }}>
              <a onClick={moreClicked}>Περισσότερα</a>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default NewsPage;
